import React from "react";
import { graphql } from "gatsby";
import { Layout } from "../components/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import { Seo }  from "../components/seo";

export const query = graphql`
  query HomePageQuery {
  page: sanityHome {
    _id
    title
    _rawFlexibleLayout
    seoSettings {
      description
      title
      openGraphImage {
        asset {
          url
        }
      }
    }
  }
}
`
const HomePage = (props) => {
  // console.log({props})
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const {page} = data;
  if (!page) {
    throw new Error(
      'Missing home page content.'
    );
  }
  // console.log(page._rawFlexibleLayout)
  return (
    <Layout title={page.title} layout={page._rawFlexibleLayout}>
       <Seo pageURL={`/`} title={page.seoSettings.title} description={page.seoSettings.description} openGraphImage={page.seoSettings?.openGraphImage?.asset?.url ? page.seoSettings?.openGraphImage?.asset?.url : null} datePublished={page._updatedAt} />
    </Layout>
  );
};


export default HomePage;